.new-jmr {
  padding: 20px 40px;
  height: 100%;
  overflow-x: hidden;
  .new-jmr__content {
    max-width: 90%;
    .new-jmr__title {
      margin: -20px 0 24px 0;
    }
    .new-jmr__title_header {
      margin: -20px 0 24px 0;
    }
    .new-jmr__background-page__alias {
      position: relative;

      .new-jmr__background-page__alias__search-icon {
        cursor: pointer;
        box-sizing: border-box;
        position: absolute;
        height: 40px;
        width: 40px;
        right: -10px;
        top: -10px;
        border-left: 1px solid #6c7778;
        padding: 7px 0 0 7px;
        color: #232f3e;
      }
    }
    .new-jmr__button-row {
      margin-top: 66px;
    }
    .new-jmr__restrictions-page__task {
      position: relative;
      .new-jmr__restrictions-page__remove-task {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 15px;
      }
      .new-jmr__restrictions-page__caret {
        cursor: pointer;
      }
    }
    .new-jmr__placements {
      .new-jmr__placements-title {
        padding-top: 30px;
      }
      .new-jmr__placements-description {
        padding-bottom: 16px;
      }
      .new-jmr__placements-table {
        padding-bottom: 64px;
        .new-jmr__placements-table__header-row {
          box-sizing: border-box;
          border-bottom: 2px solid #bbc0c1;
          padding-left: 16px;
          height: 52px;
          width: auto;
        }
        .new-jmr__placements-table__empty-row {
          height: 70px;
        }
        .new-jmr__placements-table__footer-row {
          border-top: 2px solid #bbc0c1;
          border-bottom: 1px solid #bbc0c1;
          padding-left: 16px;
          height: 72px;
        }
        .new-jmr__placements-table__left-col {
          border-right: 1px solid #e7e9e9;
          width: 220px;
          padding-left: 16px;
        }
        .new-jmr__placements-table__right-col {
          border-left: 1px solid #e7e9e9;
          width: 145px;
          padding: 0 16px;
        }
        .new-jmr__placements-table__content-row {
          border-bottom: 1px solid #e7e9e9;
          height: 110px;
          padding-left: 16px;
          width: 100%;
        }
      }
    }
  }
}
