.view-and-edit-forms {
  margin-top: 20px;

  .view-and-edit-forms__edit-icon {
    cursor: pointer;
  }
  .view-and-edit-forms__content__text {
    margin-bottom: 40px;
    max-width: 800px;
  }

  .view-and-edit-tasks__table {
    height: calc(100% - 260px);
  }
}
