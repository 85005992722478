.associate-info-modal__login-search-icon {
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    height: 40px;
    width: 40px;
    right: -10px;
    top: -10px;
    border-left: 1px solid #6c7778;
    padding: 7px 0 0 7px;
    color: #232f3e;
  }