.create-new-job {
  margin: 20px auto 60px;

  .create-new-job__heading {
    margin: 20px auto;
  }

  .create-new-job__content {
    position: relative;
    margin: 20px auto;
    box-sizing: border-box;
    min-height: 500px;

    .create-new-job__content__add-task-subtitle {
      margin: 10px 0;
    }
  }
}
