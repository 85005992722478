.default-settings {
  margin: 20px;
  height: 100%;

  .default-settings__content {
    position: relative;
    margin: 20px 0;
    box-sizing: border-box;
    width: 100%;
    height: 80%;
    min-height: 500px;

    .default-settings__content__site-row {
      .default-settings__content__site-row__delete-icon {
        cursor: pointer;
      }
    }

    .default-settings__content__button-row {
      position: absolute;
      bottom: 20px;

      & > * {
        margin-right: 10px;
      }
    }
  }
}
