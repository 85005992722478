.view-and-edit-tasks {
  margin-top: 20px;
  height: 100%;

  .view-and-edit-tasks__edit-icon {
    cursor: pointer;
  }
  .view-and-edit-tasks__content__text {
    margin-bottom: 40px;
    max-width: 800px;
  }
  .view-and-edit-tasks__open-modal {
    .view-and-edit-tasks__content__icon {
      margin: 0 10px 2px 10px;
    }
    .view-and-edit-tasks__content__link {
      cursor: pointer;
    }
  }

  .view-and-edit-tasks__table {
    height: calc(100% - 260px);
  }
}
