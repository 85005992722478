.placements-overview {
  margin: 20px;
  height: 100%;
}

.placement-overview-table-content__associate-name {
  color: #077398;
}

.placement-overview-table-content__shift-code {
  color: #697576;
}
.placement-overview-table-header__days {
  color: #697576;
}

.placement-overview-advanced-filter__days {
  color: #697576;
}

.placements-overview__filter-icon {
  cursor: pointer;
}
