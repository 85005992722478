.job-configuration {
  margin: 20px;
  height: 100%;

  .job-configuration__content {
    position: relative;
    margin: -1px 0 0 0;
    width: 100%;
  }

  .job-configuration__tab-group {
    margin: 20px 0 0 30px;
  }
}
