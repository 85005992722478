.placement-details {
  padding: 20px 40px;
  height: 100%;
  overflow-x: hidden;
  .placement-details__content {
    margin-top: 32px;

    .placement-details__top-detail-item {
      background: #f9fafa;
      padding: 8px;
      margin-top: 20px;
    }
  }
}
