.update-site-capacity {
  margin-top: 20px;
  height: 100%;

  .update-site-capacity__page-heading {
    margin-bottom: 10px;
  }

  .update-shifts__edit-icon {
    cursor: pointer;
  }
  .update-shifts__content__text {
    margin-bottom: 40px;
    max-width: 800px;
  }
  .update-shifts__open-modal {
    .update-shifts__content__link {
      cursor: pointer;
    }
  }

  .update-shifts__table {
    height: calc(100% - 260px);
  }
}
