.job-config {
  margin-top: 20px;
  height: 100%;

  .job-config__edit-icon {
    cursor: pointer;
  }
  .job-config__content__text {
    margin-bottom: 40px;
    max-width: 800px;
  }
  .job-config__add-job {
    .job-config__content__icon {
      margin: 0 10px 2px 10px;
    }
    .job-config__content__link {
      cursor: pointer;
    }
  }

  .job-config__table {
    height: calc(100% - 260px);
  }
}
