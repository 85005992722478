.site-management {
  margin: 20px;
  height: 100%;

  .site-management__content {
    position: relative;
    margin: -1px 0 0 0;
    width: 100%;
  }

  .site-management__tab-group {
    margin: 20px 0 0 30px;
  }
}
