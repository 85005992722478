// In the right column, when a job is assigned hours less than or equal to zero
// an error message shows up under the input box. This error message causes
// the input box to be misaligned with the uncheck job icon (the x button next to
// the input box). This -10px helps offset that and keep the two aligned.
.new-jmr__uncheck-job--error {
    top: -10px;
}

// Without this the error message wraps downwards and overflows across the line
// dividing the different assigned jobs. It also pushes the input box upwards and causes
// that to overflow across the line dividing the different assigned jobs.
.new-jmr__placements-table__right-col.new-jmr__placements-table__content-row{
    [mdn-alert-message] {
        max-width: none;
    }
}